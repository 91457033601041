<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title"><span>设备类型</span></div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="deviceTypeCode" label="设备类型"></el-table-column>
        </el-table>
        <!-- <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:pageChange="pageChange"></page> -->
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      tableData: [],
      roleId: 0
    };
  },
  methods: {
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    edit(row) {
      this.$router.push({ name: 'iotProductEdit', params: {row: row} })
    },
    details(row) {
      this.$router.push({ name: 'deviceListDetail', params: {row: row} })
    },
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('deviceDelete', {
          id: row.id
        }).then(res => {
          this.loadListData()
        })
      })
    },
    loadListData() {
      this.loading = true
      this.$ajax.post('iotDeviceQuery').then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    getLastPage() {
      if(sessionStorage.getItem('detail')) {
        this.page = Number(sessionStorage.getItem('currentPage'))
      }else {
        this.page = 1
        sessionStorage.removeItem('currentPage')
      }
    }
  },
  mounted() {
    this.getLastPage()
    this.loadListData()
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {

  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
</style>